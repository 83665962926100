body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.html5-video-player {
  background-color: #fff !important;
}
@font-face {
  font-family: "Optima";
  src: url(./assets/fonts/Optima.ttc);
  font-display: swap;
}
@font-face {
  font-family: "Neutra-Italic";
  src: url(./assets/fonts/NeutraText-BookItalic.otf);
  font-display: swap;
}
@font-face {
  font-family: "Neutra-Book";
  src: url(./assets/fonts/NeutraText-Book.otf);
  font-display: swap;
}
@font-face {
  font-family: "Optima-Italic";
  src: url(./assets/fonts/Optima_Italic.otf);
  font-display: swap;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

a,
a:hover {
  text-decoration: none;
}

iframe {
  height: 100%;
}
iframe#modal {
  height: calc(100vh + 60px);
  margin-top: -60px;
}
button:focus {
  outline: transparent !important;
}
a:focus {
  outline: transparent !important;
}
.react-parallax-background-children {
  width: 100% !important;
}

#containerSebastianBemberg {
  bottom: 0px !important;
}

.video-responsive {
  padding-bottom: 56.25%;
  position: relative;
  height: 100%;
  width: 100%;
}

.video-responsive iframe {
  left: 50%;
  top: 50%;
  height: calc(100% + 120px);
  transform: translate(-50%, -50%);
  width: 100%;
  position: absolute;
}
.video-btn {
  background-color: #002e6d;
  position: absolute;
  top: 1rem;
  right: calc(-135.38px - 84.75px - 48px);
  font-size: 20px;
  font-family: Optima;
  padding: 0.5rem 1.5rem;
  color: #cfd2d3;
  cursor: pointer;

  transition: color 0.4s ease, border 0.4s ease;
}
@media only screen and (max-width: 1409px) {
  .video-btn {
    right: calc(-135.38px - 48px);
  }
}
@media only screen and (max-width: 959px) {
  .video-btn {
    right: calc(-135.38px - 77px);
  }
}
.video-btn:hover {
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .video-btn {
    font-size: 18px;
    padding: 0.4rem 1.2rem;
    right: 50%;
    transform: translateX(50%);
    top: -3.5rem;
  }
}
.modal-video {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 2000;
  background-color: #000000;
}
.close-modal {
  position: absolute;
  right: 2%;
  top: 2%;
  font-size: 2rem;
  color: #cfd2d3;
  cursor: pointer;
  z-index: 10;
}
